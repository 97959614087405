import { useEffect, useRef } from "react";
import { Section } from "../pages/Product";
import { useStateStore } from "../utils/zustandStore";

const MonoColorSlider = () => {
  const sliderRef = useRef();
  const { languageState } = useStateStore();
  useEffect(() => {
    let imgMono = document.getElementById("imgMono");
    imgMono.style.width = "50%";
    let imgColor = document.getElementById("imgColor");
    imgColor.style.width = "50%";

    const handleMouseMove = (e) => {
      let imageWidth = 50;
      const slider = sliderRef.current;
      const { left, right, width } = slider.parentNode.getBoundingClientRect();
      const percent = ((e.clientX - left) / width) * 100;

      if (percent > 0 && percent < 100) {
        imageWidth = percent;
        if (e.clientX - left < 0) {
          slider.style.left = "0%";
          imageWidth = 0;
        } else if (e.clientX - left > right - left) {
          slider.style.left = "100%";
          imageWidth = 100;
        } else {
          slider.style.left = `${percent}%`;
          imageWidth = Math.floor(percent);
          imgMono.style.width = `${100 - imageWidth}%`;
          imgColor.style.width = `${imageWidth}%`;
        }
      }
    };

    const handleMouseDown = () => {
      // console.log("Mouse Down");
    };

    const handleMouseUp = () => {
      // console.log("Mouse Up");
    };

    const handleTouchMove = (e) => {
      e.preventDefault();
      const touch = e.touches[0];
      handleMouseMove(touch);
    };

    const handleTouchStart = (e) => {
      const touch = e.touches[0];
      handleMouseDown();
      handleMouseMove(touch);
    };

    const handleTouchEnd = () => {
      handleMouseUp();
    };

    sliderRef.current.parentNode.addEventListener("mousemove", handleMouseMove);
    sliderRef.current.parentNode.addEventListener("mousedown", handleMouseDown);
    sliderRef.current.parentNode.addEventListener("mouseup", handleMouseUp);
    sliderRef.current.parentNode.addEventListener("touchmove", handleTouchMove);
    sliderRef.current.parentNode.addEventListener("touchstart", handleTouchStart);
    sliderRef.current.parentNode.addEventListener("touchend", handleTouchEnd);

    return () => {
      sliderRef.current?.parentNode?.removeEventListener("mousemove", handleMouseMove);
      sliderRef.current?.parentNode?.removeEventListener("mousedown", handleMouseDown);
      sliderRef.current?.parentNode?.removeEventListener("mouseup", handleMouseUp);
      sliderRef.current?.parentNode?.removeEventListener("touchmove", handleTouchMove);
      sliderRef.current?.parentNode?.removeEventListener("touchstart", handleTouchStart);
      sliderRef.current?.parentNode?.removeEventListener("touchend", handleTouchEnd);
    };
  }, []);
  return (
    <Section className={`mt-[7.5em] md:mt-0`}>
      <div className="relative bg-black rounded-xl gap-[2em] md:gap-[5em] w-full p-[4vw_5.25vw_5.25vw] md:p-[2.5vw_4.5vw] flex flex-col md:flex-row justify-between z-10">
        <div className="text-white flex flex-col gap-[1vw] md:max-w-[50%] md:mt-[3.75em]">
          <span className="text-[7vw] md:text-[4vw] font-PretendardBL">SyncRo</span>
          <span className="text-[3.75vw] md:text-[1.875vw] whitespace-pre-wrap">
            {languageState === "kr"
              ? "처음부터 정확하게, 품질은 균일하게"
              : `Be Precise from the Start,\nMaintain Consistent Quality`}
          </span>
        </div>
        <div className="relative md:max-w-[50%] select-none aspect-[1/1] h-fit bg-[#717071] rounded-xl overflow-hidden">
          <picture className="w-full h-full flex">
            <img
              src="https://staticsrc.s3.ap-northeast-2.amazonaws.com/images/SyncRo.web/product/3_%EC%97%94%EC%A7%84%EC%BB%A4%EB%B2%84-02.jpg"
              alt=""
              className="h-full object-cover object-left"
              draggable={false}
              id="imgColor"
            />{" "}
            <img
              src="https://staticsrc.s3.ap-northeast-2.amazonaws.com/images/SyncRo.web/product/3_%EC%97%94%EC%A7%84%EC%BB%A4%EB%B2%84-01.jpg"
              alt=""
              className="h-full object-cover object-right"
              draggable={false}
              id="imgMono"
            />
          </picture>
          <div
            ref={sliderRef}
            id="SliderView"
            className="absolute flex items-center justify-center top-0 left-[50%] h-full w-[.25rem] bg-transparent z-[20] overflow-visible select-none cursor-pointer"
            draggable={false}
          >
            {/* <div
              className="absolute top-[calc(50%_-_1.5rem)] w-[3rem] h-[3rem] rounded-full bg-[rgba(74,160,216,0.75)] border-[3px] z-[30]"
              draggable={false}
            /> */}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default MonoColorSlider;
